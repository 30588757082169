import React from 'react';
import InteractiveStory from "../InteractiveStory";

const StoryPage = () => {
    return (
        <>
            <div id={"interactive-story"}>
            <InteractiveStory></InteractiveStory>
                </div>
        </>
    );
};

export default StoryPage
