import React, { useState, useEffect } from 'react';
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import {
    Nav,
    NavbarContainer,
    MobileIcon,
    NavMenu,
    NavItem,
    NavLinks,
    NavBtn,
    NavBtnLink
} from './NavbarElements';
import LoginButton from "../LoginButton";
import RegisterButton from "../RegisterButton";
import {useAuth0} from "@auth0/auth0-react";
import LogoutButton from "../LogoutButton";

const Navbar = ({ toggle }) => {
    const { isAuthenticated } = useAuth0();
    const [scrollNav, setScrollNav] = useState(false);

    //when scroll past 80px, trigger header background
    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, []);


    function goToHome() {
      const currentLocation = window.location.href;
      const baseURL = currentLocation.split('/').slice(0, 3).join('/'); // Extract the base URL

      return baseURL; // Navigate to the home page
    }

    return (
        <>
            <IconContext.Provider value={{ color: "#fff" }}>
                <Nav scrollNav={scrollNav}>
                    <NavbarContainer>
                        <a href={goToHome()} style={{height: "80px"}}>
                        <img src={process.env.PUBLIC_URL + "/logo.png"} alt="Imagine Story" style={{height: "80px"}}/>
                        </a>
                        <MobileIcon onClick={toggle}>
                            <FaBars />
                        </MobileIcon>
                        <NavMenu>
														<NavItem>
                                <NavLinks to="stories"
                                    smooth={true}
                                    duration={500}
                                    spy={true}
                                    exact='true'
                                    offset={-80}>Stories</NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to="about-us"
                                    smooth={true}
                                    duration={500}
                                    spy={true}
                                    exact='true'
                                    offset={-80}>About Us</NavLinks>
                            </NavItem>
														<NavItem>
                                <NavLinks to="contact"
                                    smooth={true}
                                    duration={500}
                                    spy={true}
                                    exact='true'
                                    offset={-80}>Contact</NavLinks>
                            </NavItem>
                        </NavMenu>
                        {isAuthenticated ? <LogoutButton/> : (<><LoginButton/> <RegisterButton/></>)}
                    </NavbarContainer>
                </Nav>
            </IconContext.Provider>
        </>
    )
}

export default Navbar;
