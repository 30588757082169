import React from 'react';
import './ErrorMessage.css'; // Make sure to import the CSS file

const ErrorMessage = ({ message }) => {
  return (
    <div className="error-message">
      <i className="fas fa-exclamation-circle"/>
      <span className="error-text">{message}</span>
    </div>
  );
};

export default ErrorMessage;
