import React from 'react';
import {NavBtn, NavBtnLink} from "../Navbar/NavbarElements";
import { useAuth0 } from "@auth0/auth0-react";

const RegisterButton = () => {
      const { loginWithRedirect } = useAuth0();

  return (
    <NavBtn>
        <NavBtnLink onClick={() => loginWithRedirect({authorizationParams: {
                      screen_hint: "signup",
                    }})}>Register</NavBtnLink>
    </NavBtn>
  );
};

export default RegisterButton;
