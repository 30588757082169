import React from 'react';
import InfoSection from "../components/InfoSection";
import {homeObjOne, homeObjTwo} from "../components/InfoSection/Data";
import ContactForm from "../components/ContactForm";
import {contactForm} from "../components/ContactForm/Data";

const HomePage = () => {
    return (
          <>
            <InfoSection {...homeObjTwo}/>
            <InfoSection {...homeObjOne}/>
            <ContactForm {...contactForm}/>
          </>
    );
};

export default HomePage
