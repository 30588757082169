import React from 'react';
import {NavBtn, NavBtnLink} from "../Navbar/NavbarElements";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = () => {
      const { logout } = useAuth0();

  return (
    <NavBtn>
        <NavBtnLink onClick={() => logout()}>Logout</NavBtnLink>
    </NavBtn>
  );
};

export default LogoutButton;
