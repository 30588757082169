import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import {Outlet, Route, Routes} from "react-router-dom";
import HomePage from "./HomePage";
import StoryPage from "./StoryPage";
import {AuthenticationGuard} from "../components/AuthenticationGuard";

const Home = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    };


    return (
        <>
            <Navbar toggle={toggle}/>
            <Routes>
                <Route path="/interactive-story" element={<AuthenticationGuard component={StoryPage}/>} />
                <Route path="*" element={<HomePage />} />
            </Routes>
            <Outlet/>
            <Footer />
        </>
    );
};

export default Home;
