import React from "react";
import './VerifyEmail.css'; // Import your CSS file for styling

const EmailNotVerified = ({email}) => {
  return (
    <div className="email-not-verified">
      <h2>Email Not Verified</h2>
      <p>
        Your email (<strong>{email}</strong>) is not verified.
        Please check your inbox and click the verification link to continue.
      </p>
      {/* Add any additional content or actions you want */}
    </div>
  );
};

export default EmailNotVerified;
