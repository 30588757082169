import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import EmailNotVerified from "../VerifyEmail";

export const AuthenticationGuard = ({ component }) => {
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const { user } = useAuth0();

  useEffect(() => {
    if (user) {
      setIsEmailVerified(user.email_verified);
      setIsRegistered(true);
    }
    else {
      setIsRegistered(false);
    }
  }, [user]);

  if (isRegistered && !isEmailVerified) {
    console.log("registered & not verified");
    return <EmailNotVerified email={user.email}/>;
  }

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="page-layout">
        <span>Loading</span>
      </div>
    ),
  });

  return <Component />;
};
