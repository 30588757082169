import React from 'react';
import './RetryButton.css'; // Make sure to import the CSS file

const RetryComponent = ({ onRetry }) => {
  return (
    <div className="retry-component">
      <button className="retry-button" onClick={onRetry}>
                <i className="fas fa-redo-alt reload-icon" />
        Retry
      </button>
    </div>
  );
};

export default RetryComponent;
