import React from 'react';
import {NavBtn, NavBtnLink} from "../Navbar/NavbarElements";
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
      const { loginWithRedirect } = useAuth0();

  return (
    <NavBtn>
        <NavBtnLink style={{background: "#000", color: "#fff"}} onClick={() => loginWithRedirect()}>Login</NavBtnLink>
    </NavBtn>
  );
};

export default LoginButton;
