export const homeObjOne = {
    id: 'about-us',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'About us',
    headline: 'Our vision',
    description: 'Welcome to our magical world of storytelling! ' +
        'At Imagine Story, we are on a mission to ignite children\'s imaginations and make storytelling an extraordinary adventure. ' +
        'Powered by the latest AI technology, we craft captivating and personalized stories that transport young minds to enchanting realms, where they become the heroes of their own tales. ' +
        'Our platform\'s AI wizardry weaves together unique characters, thrilling plots, and interactive elements, creating an immersive experience like no other. ' +
        'With each story crafted just for them, children can explore endless possibilities and embark on unforgettable journeys. ' +
        'Join us at Imagine Story and let your child\'s imagination take flight!',
    buttonLabel: 'Count me in!',
    img: process.env.PUBLIC_URL + "/logo.png",
    imgStart: false,
    alt: 'Imagine Story',
    dark: true,
    primary: true,
    darkText: false,
};

export const homeObjTwo = {
    id: 'stories',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Imagine your own story!',
    headline: 'Start your journey',
    description: 'Embark on an extraordinary personalized journey with us, as we invite you to join our AI-powered platform. ' +
        'By becoming a user, you\'ll unlock a world of customized experiences tailored to your unique preferences and needs. ',
    buttonLabel: "Let's go!",
    imgStart: true,
    img: process.env.PUBLIC_URL + "/logo.png",
    alt: 'Story',
    dark: false,
    primary: false,
    darkText: true,
};
