import React, {useEffect, useRef} from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages'
import SigninPage from './pages/signin';
import {BACKEND_URL} from "./config";

function App() {
    const isMounted = useRef(false);

    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;
            fetch(BACKEND_URL + "/health", {
                method: "GET",
            }).then((response) => {
                console.log("healthy");
            }).catch((error) => {
                console.log("not healthy");
            })
        }
    }, []);



  return (
    <Router>
      <Routes>
        <Route path="*" element={<Home />} />
          <Route path="/signin" element={<SigninPage />} />
      </Routes>
    </Router>
  );
}

export default App;
