import React, { useState } from 'react';
import Form from './Form';
import Result from './Result';
import {RESPONSES} from "./mocks";
import './InteractiveStory.css'; // Import the CSS file
import { v4 as uuidv4 } from 'uuid';



function InteractiveStory() {
  const [formData, setFormData] = useState({
    name: '',
    gender: '',
    age: '',
    theme: '',
  });

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [storyPromise, setStoryPromise] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsFormSubmitted(true);

        // Get the form values
  const form = event.target;
  const name = form.elements.name.value;
  const gender = form.elements.gender.value;
  const age = form.elements.age.value;
  const genre = form.elements.genre.value;
  const storyId = uuidv4().split('-')[0];

    // Construct the nested JSON object
    const formData = {
      story_id: storyId,
      personas: [{
        name: name,
        gender: gender,
        age: parseInt(age),
      }],
      genres: [genre],
    };

    var promise;

    // if (mock) {
    //   promise = new Promise((resolve) => {
    //     // Simulate async operation
    //     setTimeout(() => {
    //       const jsonResponse = JSON.stringify(RESPONSES["initial"]);
    //       const response = new Response(jsonResponse, {
    //         status: 200,
    //         headers: {"Content-Type": "application/json"},
    //       });
    //       resolve(response);
    //     }, 2000);
    //   });
    //   setStoryPromise(promise);
    // }
    // else {
      setStoryPromise(formData);
    // }

  };

return (
    <div style={{ overflow: "auto" }}>
      <div style={{height: "80px"}}></div>
        <div>
          {isFormSubmitted ? (
            <Result storyDataPromise={storyPromise} />
          ) : (
            <Form
              formData={formData}
              handleInputChange={handleInputChange}
              handleSubmit={handleSubmit}
            />
          )}
        </div>
      </div>
  );
}

export default InteractiveStory;
