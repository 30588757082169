// Result.js
import React, {useEffect, useRef, useState} from 'react';
import './Result.css'; // Import the CSS file
import {fetchEventSource} from "@microsoft/fetch-event-source";

import {BACKEND_URL} from './config';
import ErrorMessage from "./components/ErrorMessage";
import RetryComponent from "./components/RetryButton";
import {MoonLoader} from "react-spinners";
import {useAuth0} from "@auth0/auth0-react";


const FAVORITE_TEXT_TO_SPEECH_VOICES = ["Microsoft Zira - English (United States)", "Microsoft David - English (United States)"];


function Result({storyDataPromise}) {
    const synthesis = window.speechSynthesis;
    const isMounted = useRef(false);
    const [story, setStory] = useState({
        name: "",
        chapters: [],
        selected_options: [],
        story_metadata: storyDataPromise
    });
    const [speechEnabled, setSpeechEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [highlightIndex, setHighlightIndex] = useState(-1);
    const [storyNameText, setStoryNameText] = useState("");
    const [storyText, setStoryText] = useState("");
    const [currentChapterDrawingText, setCurrentChapterDrawingText] = useState("");
    const [chapterOptions, setChapterOptions] = useState([]);
    const [imageURL, setImageURL] = useState(null);
    const [isImageLoadingError, setIsImageLoadingError] = useState(false);
    const [imageLoadingErrorMessage, setImageLoadingErrorMessage] = useState("Failed to generate image");

    const {isAuthenticated, getAccessTokenSilently} = useAuth0();

    const getAccessToken = () => {
        if (isAuthenticated) {
            try {
                return getAccessTokenSilently();
            } catch (error) {
                console.error('Error fetching access token:', error);
                throw error;
            }
        }
    };


    const setChapterImageWithToken = (chapterDrawingDescription) => {
        getAccessToken().then((response) => {
            console.log("Got token!");
            setChapterImage(chapterDrawingDescription, response);
        }).catch((error) => {
            console.log("Failed to authenticate");
            setIsImageLoadingError(true);
        });
    };

    const setChapterImage = (chapterDrawingDescription, token) => {
        setIsImageLoadingError(false);
        setCurrentChapterDrawingText(chapterDrawingDescription);
        console.log("chapter drawing: " + chapterDrawingDescription);
        fetch(BACKEND_URL + "/draw_image", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify({chapter_drawing: chapterDrawingDescription}),
            }
        )
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 429) {
                        setImageLoadingErrorMessage("Out of credits for text-to-image API");
                    }
                    setIsImageLoadingError(true);
                    console.log("Error while fetching image");
                    return null;
                }
                return response.arrayBuffer();
            })
            .then((imageData) => {
                if (imageData === null) {
                    setIsImageLoadingError(true);
                    return;
                }
                console.log(imageData);
                // Convert the image data to a blob
                const imageBlob = new Blob([imageData], {type: "image/jpeg"});

                // Create a URL for the blob
                const imageUrl = URL.createObjectURL(imageBlob);

                // Set the image URL to display the image
                setImageURL(imageUrl);
            })
            .catch((error) => {
                console.error("Error fetching image:", error);
                setIsImageLoadingError(true);
            });
    };


    const appendStoryText = (newValue) => {
        setStoryText((prevValue) => prevValue + newValue);
    };
    const resetStoryText = () => {
        console.log("Reset story text");
        setStoryText('');
    };
    const setStoryName = (name) => {
        setStory((prevStory) => ({
            ...prevStory,
            name: name,
        }));
        setStoryNameText(name);
    };

    const handleOnMessageEvent = (msg) => {
        if (msg.event === "chapter_text") {
            appendStoryText(msg.data);
        } else {
            if (msg.event === "story_name") {
                console.log("story name: " + msg.data);
                setStoryName(msg.data);
            }
            if (msg.event === "chapter_drawing") {
                console.log("chapter drawing: " + msg.data);
                setChapterImageWithToken(msg.data);
            } else {
                if (msg.event === "options") {
                    console.log("added options");
                    let options = JSON.parse(msg.data);
                    setChapterOptions(options);
                }
            }
        }
    };

    function onStoryStreamClose() {
        console.log("closed connection");
        setIsLoading(false);
    }

    function onStoryStreamError(err) {
        console.log("error in story stream");
        let shouldRetry = false;
        if (!shouldRetry) {
            throw err; // rethrow to stop the operation
        } else {
            // do nothing to automatically retry. You can also
            // return a specific retry interval here.
        }
    }

    function storyRequest(route, body, token) {

        const fetchData = async () => {
            await fetchEventSource(BACKEND_URL + route, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify(body),
                openWhenHidden: true,
                onopen(response) {
                    if (!response.ok) {
                        console.log("Error while opening streaming request: " + response.status);
                        handleStoryFailure();
                    } else {
                        onStoryStreamOpen();
                    }
                },
                onmessage(msg) {
                    handleOnMessageEvent(msg);
                },
                onclose() {
                    onStoryStreamClose();
                },
                onerror(err) {
                    onStoryStreamError(err);
                }
            })
        };
        fetchData().then(() => {
            console.log("Streaming request completed");
        }).catch((error) => {
            console.log("Got error on streaming request");
            console.log(error);
            handleStoryFailure();
        });
    }

    function storyRequestWithToken(route, body) {
        getAccessToken().then((response) => {
            console.log("Got token!");
            storyRequest(route, body, response);
        }).catch((error) => {
            console.log("Failed to authenticate");
            setIsImageLoadingError(true);
        });
    }

    function nextChapterRequest(isRetry) {
        const route = isRetry ? "/retry_next_chapter" : "/next_chapter";
        storyRequestWithToken(route, story);
    }

    function initialStoryRequest(isRetry) {
        const route = isRetry ? "/retry_interactive_story" : "/interactive_story";
        storyRequestWithToken(route, storyDataPromise);
    }

    useEffect(() => {
        if (!isMounted.current) {
            // Code to run on component load
            console.log('Result component loaded');
            isMounted.current = true;

            // Code to run on component load
            console.log("promise ");
            console.log(storyDataPromise);
            initialStoryRequest(false);

        }

        // You can also perform other operations such as fetching data, subscribing to events, etc.
        // Just make sure to clean up any necessary resources in the cleanup function (returned by useEffect) if needed.

        // Optional cleanup function
        return () => {
            // Code to clean up any resources if needed
            // This will be called when the component is unmounted or before the effect is run again.
        };
    }, []); // Empty dependency array ensures the effect is only run once on component load


    const handleSpeechToggle = () => {
        if (!speechEnabled) {
            speakText(storyText);
        } else {
            stopSpeech();
        }
        setSpeechEnabled(!speechEnabled);
    };

    function setVoice(utterance) {
        const voices = synthesis.getVoices();
        for (const preferredVoice of FAVORITE_TEXT_TO_SPEECH_VOICES) {
            const selectedVoice = voices.find(voice => voice.name === preferredVoice);
            if (selectedVoice) {
                console.log("Selected voice: " + selectedVoice);
                utterance.voice = selectedVoice;
                utterance.rate = 0.7;
                return;
            }
        }
    }

    function handleEndOfSpeechEvent() {
        setHighlightIndex(-1);
    }

    const speakText = (text) => {
        const utterance = new SpeechSynthesisUtterance(text);
        setVoice(utterance);
        utterance.addEventListener('boundary', handleBoundaryEvent);
        utterance.addEventListener('end', handleEndOfSpeechEvent);
        synthesis.speak(utterance);
    };

    const stopSpeech = () => {
        if (!synthesis) return;

        synthesis.cancel();
        setHighlightIndex(-1);
    };

    const handleBoundaryEvent = (event) => {
        // todo: there is a bug in these events, for example:
        //  "he loved the most - his favorite teddy bear"
        //  the "-" char is not considered a word. then, the index is not promoted,
        //  and there is a gap of 1 word in the highlighting.
        if (event.name === 'word') {
            // console.log(event)
            setHighlightIndex((prevIndex) => prevIndex + 1);
        } else {
            console.log(event);
        }
    };

    function onStoryStreamOpen() {
        console.log("open");
        setIsLoading(true);
    }

    function handleStoryFailure() {
        setIsError(true);
    }

    const userSelectedOption = (optionIdx, optionText) => {
        console.log("user selected option");
        console.log("options:" + chapterOptions);
        console.log("text:" + storyText);
        // appendNewChapter();
        story.chapters.push({
            "text": storyText,
            "drawing_text": currentChapterDrawingText,
            "options": chapterOptions,
        });
        story.selected_options.push({
            index: optionIdx,
            text: optionText,
        });
        resetStoryText();
        setChapterOptions([]);
        nextChapterRequest(false);
    };

    function retryStory() {
        setIsError(false);
        resetStoryText();


        if (story.chapters.length === 0) {
            console.log("New story retry");
            setStoryNameText("");
            initialStoryRequest(true);
        } else {
            console.log("Next chapter retry");
            nextChapterRequest(true);
        }

    }

    return (
        <div className="result-container">
            <div style={{display: "flex"}}><h1> {storyNameText} </h1></div>
            <div className="speech-button-container" onClick={handleSpeechToggle}>
                <button className="speech-button">
                    {speechEnabled ? (
                        <span className="icon"> <i className="fas fa-volume-up"></i></span>
                    ) : (
                        <span className="icon"><i className="fas fa-volume-mute"></i></span>
                    )}
                </button>
            </div>
            <div className="story-container">
                <div className="text-content">
                    <div className="login-wrap">
                        <div className="chapter-text-container">
                            {storyText.split(/\s+/).map((word, index) => (
                                <span key={index} className={index === highlightIndex ? 'highlighted-word' : ''}>{word}{' '}</span>
                            ))}
                        </div>
                        <div className="chapter-image-container">
                            {isImageLoadingError ? <ErrorMessage message={imageLoadingErrorMessage}/> : (imageURL ?
                                <img className="chapter-image" src={imageURL}/> :
                                <><MoonLoader color="#36d7b7" cssOverride={{display: "flex", "align-items": "center"}} speedMultiplier={0.1}/>
                                    <div>Generating Image...</div>
                                </>)}
                        </div>
                    </div>
                </div>
            </div>


            {isError ?

                <div>
                    <ErrorMessage message={"We ran into a problem while making up a story"}/>
                    <RetryComponent onRetry={retryStory}/>
                </div>
                : isLoading ? <span/> : (chapterOptions.length === 0 ? (storyText === "" ? <span/> :
                        <h4> THE END! </h4>) :
                    <div className="radio-container">
                        {/*<input type="radio" id="radio1" name="radio" value={chapterOptions[0].text}*/}
                        {/*       onChange={(event) => userSelectedOption(0, event)}/>*/}
                        <div className="radio-box">
                            <div>
                                <span className="radio-text">{chapterOptions[0].text}</span>
                            </div>
                            <div className="radio-button-label">
                                <button onClick={() => userSelectedOption(0, chapterOptions[0].text)}>Choose me</button>
                            </div>
                        </div>
                        <div className="radio-box">
                            {/*<input type="radio" id="radio2" name="radio" value={chapterOptions[1].text}*/}
                            {/*       onClick={(event) => userSelectedOption(1, event)}/>*/}
                            <div>
                                <span className="radio-text">{chapterOptions[1].text}</span>
                            </div>
                            <div className="radio-button-label">
                                <button onClick={() => userSelectedOption(1, chapterOptions[1].text)}>Choose me</button>
                            </div>
                        </div>
                    </div>)
            }


        </div>
    );
}

export default Result;
