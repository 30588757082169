import React, { useEffect, useState } from 'react';
import './Form.css'; // Import the CSS file


function Form({ formData, handleInputChange, handleSubmit, isLoading }) {

  const [eyeBallSize, setEyeBallSize] = useState({ width: 0, height: 0 });

  const handleMouseMove = (event) => {
    const dw = document.documentElement.clientWidth / 15;
    const dh = document.documentElement.clientHeight / 15;
    const x = event.pageX / dw;
    const y = event.pageY / dh;
    setEyeBallSize({ width: x, height: y });
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);


  return (
    <div>
      <div className="panda">
        <div className="ear"></div>
        <div className="face">
          <div className="eye-shade"></div>
          <div className="eye-white">
            <div className="eye-ball" style={eyeBallSize}></div>
          </div>
          <div className="eye-shade rgt"></div>
          <div className="eye-white rgt">
            <div className="eye-ball" style={eyeBallSize}></div>
          </div>
          <div className="nose"></div>
        </div>
        <div className="body"></div>
        <div className="foot">
          <div className="finger"></div>
        </div>
        <div className="foot rgt">
          <div className="finger"></div>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="hand"></div>
        <div className="hand rgt"></div>
        <div className="hand:after"></div>
        <div className="hand:before"></div>
        <h1>Create your own story</h1>
        <div className="form-group">
          <input
            type="text"
            name="name"
            placeholder="First name"
            value={formData.name}
            onChange={handleInputChange}
            required
            className="form-control"
          />
        </div>
        <div className="form-group">
          <select
            name="gender"
            value={formData.gender}
            onChange={handleInputChange}
            required
            className="form-control"
          >
            <option value="">Select Gender</option>
            <option value="Boy">Boy</option>
            <option value="Girl">Girl</option>
          </select>
        </div>
        <div className="form-group">
  <select
    id="age"
    name="age"
    value={formData.age}
    onChange={handleInputChange}
    required
    className="form-control"
  >
    <option value="">Select an age</option>
    {/* Age options limited to 1 to 17 */}
    {Array.from({ length: 17 }, (_, index) => index + 1).map((age) => (
      <option key={age} value={age}>{age}</option>
    ))}
  </select>
</div>

        <div className="form-group">
  <select
    id="genre"
    name="genre"
    value={formData.genre}
    onChange={handleInputChange}
    required
    className="form-control"
  >
    <option value="">Select a genre</option>
    <option value="picture-books">Picture Books</option>
    <option value="fairy-tales">Fairy Tales</option>
    <option value="adventure">Adventure</option>
    <option value="fantasy">Fantasy</option>
    <option value="science-fiction">Science Fiction</option>
    <option value="mystery">Mystery</option>
    <option value="historical-fiction">Historical Fiction</option>
    <option value="animal-stories">Animal Stories</option>
    <option value="humor">Humor</option>
    <option value="poetry">Poetry</option>
    <option value="non-fiction">Non-fiction</option>
    <option value="high-tech">High-Tech</option>
    <option value="gaming">Gaming</option>
  </select>
</div>

        <button type="submit" className="btn" disabled={isLoading}>
          Submit
        </button>
      </form>
    </div>
  );
}

export default Form;
